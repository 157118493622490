.offer-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.offer-pg__text_body {
  width: 775px;
}

.offer-pg__photo_pc {
  margin-top: 82px;
}

.offer-pg__photo-example-text {
  align-self: center;
}


.offer-pg__block {
  margin-top: 122px;
}

.offer-pg__clue-line {
  position: absolute;
  top: -202px;
  margin-left: -47px;
}

.offer-pg__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 90px;
  padding: 0;
  width: 960px;
}

.offer-pg__list-element {
  display: flex;
  max-width: calc(50% - 13px);
}

.offer-pg__list-element:nth-child(even) {
  align-self: flex-end;
}

.offer-pg__algorithm-list > .offer-pg__list-element:last-child {
  margin-bottom: 0;
  margin-right: 100px;
}

.offer-pg__algorithm-list > .offer-pg__list-element:nth-child(1) {
  width: 466px;
}

.offer-pg__algorithm-list > .offer-pg__list-element:nth-child(2) {
  width: 416px ;
  /* margin-top: -69px; */
  margin-right: 48px;
}

.offer-pg__algorithm-list > .offer-pg__list-element:nth-child(3) {
  margin-top: -66px;
}

.offer-pg__algorithm-list > .offer-pg__list-element:nth-child(4) {

}

.offer-pg__algorithm-list > .offer-pg__list-element:nth-child(5) {
  margin-top: -4px;
}

.offer-pg__algorithm-list > .offer-pg__list-element:nth-child(6) {
  margin-right: 259px;
}


.offer-pg__element-number {
  display: flex;
  justify-content: flex-start;
  /* height: 124px; */
  width: 100px;
  height: 122px;
}

.offer-pg__element-number > img {
  width: inherit;
  height: inherit;
}

.offer-pg__element-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
}

.offer-pg__element-desc-title {
  margin-bottom: 12px;
}

.offer-pg__element-desc-text {
  margin-top: 12px;
}

.offer-pg__algorithm-step-title {
  height: 56px;
}

.offer-pg__element-dot {
  position: relative;
  margin-top: 8px;
  width: 19px;
  height: 18px;
  background: var(--surface-color);
  border-radius: 50%;
  box-shadow: 6.26984px 6.26984px 15.0476px rgba(166, 180, 200, 0.7), -7.52381px -7.52381px 12.5397px rgba(255, 255, 255, 0.8);
}

.offer-pg__element-dot > .gradient-border {
  border-radius: 50%;
}

.offer-pg__advantages {
  margin-top: 120px;
  padding-right: 75px;
}

.offer-pg__list-advantages > .offer-pg__list-element:nth-child(1) {
  margin-left: -47px;
}

.offer-pg__list-advantages > .offer-pg__list-element:nth-child(2) {
  margin-top: -44px;
  margin-right: 9px;
}

.offer-pg__quality-icon {
  width: 200px;
  height: 166px;
}

.offer-pg__speed-icon {
  width: 200px;
  height: 179px;
}

.offer-pg__element-desc_quality {
  padding-top: 10px;
}

.offer-pg__element-desc_speed {
  padding-top: 10px;
}

.offer-pg__services {
  margin-top: 95px;
}

.offer-pg__additional-service {
  margin-bottom: 72px;
}

.offer-pg__additional-service:nth-child(1) {
  max-width: 569px;
}

.offer-pg__additional-service:nth-child(2) {
  max-width: 501px;
  align-self: center;
}

.offer-pg__additional-service:nth-child(3) {
  align-self: flex-end;
  margin-bottom: 0;
  max-width: 549px;
}

.offer-pg__additional-service > .offer-pg__element-desc {
  padding-top: 0;
}

.offer-pg__add-service-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 40px;
  background: var(--surface-color);
}

.offer-pg__custom-dev-icon {
  width: 65px;
  height: 65px;
}

.offer-pg__custom-des-icon {
  width: 60px;
  height: 60px;
}

.offer-pg__ads-icon {
  width: 62px;
  height: 62px;
}

.offer-pg__element-button {
  font-weight: 800;
  border-radius: 25px;
  padding: 17px 30px;
  margin-top: 20px;
}

.offer-pg__order-button {
  align-self: center;
  margin-top: 120px;
}

/* --> Mobile styles */
.offer-pg__clue-line_mob {
  position: absolute;
  top: 10px;
  left: 0;
  /* margin-left: -47px; */
  height: 3021px;
}

.offer-pg__photo_mob {
  align-self: center;
  margin-top: 36px;
  margin-bottom: 10px;
  width: 100%;
}

.offer-pg__quality-icon-wrapper_mob {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--surface-color);
  margin-right: 20px;
}

.offer-pg__quality-icon_mob {
  width: 42px;
  height: 42px;
}

.offer-pg__speed-icon_mob {
  width: 52px;
  height: 52px;
}

@media screen and (max-width: 1070px) {
  .offer-pg__text_body {
    width: 100%;
  }

  .offer-pg__block {
    margin-top: 72px;
  }

  .offer-pg__list {
    width: 100%;
    margin-top: 48px;
  }

  .offer-pg__list-element {
    width: 100% !important;
    align-self: auto !important;
    max-width: 100%;
    margin: 24px 0 !important;
  }

  .offer-pg__list-element:first-child {
    margin-top: 0 !important;
  }

  .offer-pg__list-element:last-child {
    margin-bottom: 0 !important;
  }

  .offer-pg__element-number {
    width: 72px;
    height: 100px;
  }

  .offer-pg__algorithm-list {
    margin-top: 39px;
  }

  .offer-pg__algorithm-step-title {
    height: 40px;
    margin-bottom: 15px;
  }

  .offer-pg__element-dot {
    width: 13px;
    height: 12px;
    margin-top: 0;
  }

  .offer-pg__order-button {
    margin-top: 72px;
    font-size: 24px;
  }

  .offer-pg__advantages {
    padding-right: 25px;
  }

  .offer-pg__list-advantages > .offer-pg__list-element {
    width: 100% !important;
    height: 100% !important;
  }

  .offer-pg__element-desc {
    padding-top: 9px;
  }

  .offer-pg__element-desc_quality {
    padding-top: 0;
  }

  .offer-pg__element-desc_speed {
    padding-top: 0;
  }

  .offer-pg__add-service-icon {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .offer-pg__custom-dev-icon {
    width: 46px;
    height: 46px;
  }
  
  .offer-pg__custom-des-icon {
    width: 46px;
    height: 46px;
  }
  
  .offer-pg__ads-icon {
    width: 43px;
    height: 43px;
  }
}
/* Mobile styles <-- */