.spring-grid {
  position: relative;
  width: 984px;
  display: flex;
  flex-direction: column;
}

.spring-grid__visible {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  will-change: height;
}

.spring-grid__reference {
  position: absolute;
  width: 984px;
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  z-index: -1;
}

@media screen and (max-width: 1070px) {
  .spring-grid {
    width: 100%;
  }

  /* .spring-grid__visible {
    flex-direction: column;
    flex-wrap: nowrap;
  } */

  .spring-grid__reference {
    /* flex-direction: column;
    flex-wrap: nowrap; */
    width: 100%;
  }
}