:root {
  --background-color: #E9EDF0;
  --surface-color: linear-gradient(165.78deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
  --font-family: 'Montserrat';
  --text-color: #353535;
  --text-color_light: #9E9E9E;
  --accent-color: #F857DE;
  --primary-color: #556CE4;
  --primary-color_light: #70A0FF;
  --error-color: #FF00D6;
  --border-color: linear-gradient(140deg, #FEFEFF 4.98%, #C6CEDA 94.88%)
}