.footer {
  width: 100%;
  background-color: var(--background-color);
  z-index: 1000;
  padding-top: 0;
}

.footer__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1056px;
  height: 100%;
  padding: 30px 48px 48px;
}

.footer__nav {
  display: flex;
  /* width: 100%; */
  margin-bottom: 36px;
}

.footer__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 146px;
  height: 42px;
  border-radius: 20px;
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5), -10px -10px 20px rgba(255, 255, 255, 0.5);
  transition: box-shadow .3s;
}

.footer__scroll-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 121px;
  height: 66px;
  border-radius: 20px;
}

.footer__scroll-top > svg {
  margin-bottom: 12px;
}

.footer__contacts {
  display: flex;
}

.footer__contact {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 100%;
  font-weight: 500;
  color: var(--text-color);
  margin-right: 18px;
}

.footer__contact:last-child {
  margin-right: 0;
}

.footer__logo-wrapper {
  display: flex;
  margin-bottom: 30px;
}

.footer__logo {
  width: 113px;
  height: 176px;
}

/* --> Mobile styles */
.footer__nav-col {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 23px;
}

.footer__nav-element {
  display: flex;
  margin-bottom: 12px;
}

.footer__nav-element:last-child {
  margin-bottom: 0;
}
.footer__button_selected {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  transition: box-shadow .3s;
}

.footer__button_selected:hover {
  box-shadow: inset 9px 9px 13px rgba(166, 180, 200, 0.75),
      inset -9px -9px 18px rgba(255, 255, 255, 0.75);
      transition: box-shadow .3s;
}

.footer__button_selected:active {
  box-shadow: inset 13px 13px 18px rgba(166, 180, 200, 0.75),
      inset -13px -13px 23px rgba(255, 255, 255, 0.75) !important;
}

.footer__logo_mob {
  width: 75px;
  height: 117px;
}

@media screen and (max-width: 1070px) {
  .footer__content {
    width: 100%;
  }
}

@media screen and (max-width: 850px)  {
  .footer__content {
    padding: 24px 20px 47px 12px;
  }

  .footer__nav {
    margin-bottom: 50px;
  }
  
  .footer__nav-col {
    display: flex;
  }

  .footer__contact {
    font-size: 14px;
    margin-right: 12px;
  }

  .footer__contacts-row {
    margin-bottom: 20px;
  }

  .footer__contact-icon-mail {
    width: 15px;
    height: 14px;
  }
  
  .footer__contact-icon-phone {
    width: 10px;
    height: 14px;
  }

  .footer__contact {
    font-size: 14px;
  }

  .footer__contacts-row {
    margin-bottom: 20px;
  }

  .footer__contact-icon-mail {
    width: 15px;
    height: 14px;
  }
  
  .footer__contact-icon-phone {
    width: 10px;
    height: 14px;
  }

  .footer__logo-wrapper {
  }
}
/* Mobile styles <-- */