.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form__input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
}

.form__input {
  max-width: 470px;
}

.form__error-text {
  margin-left: 40px;
}

.form__c-number {
  font-size: 16px;
  font-weight: 700;
  margin-left: 16px;
}

.form__bottom {
  display: flex;
  align-items: flex-start;
  margin-top: 72px;
}

.form__button {
  border-radius: 30px;
  padding: 16px 53px;
}

.form__result-wrapper {
  margin-left: 18px;
  max-width: 370px;
}

.form__popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(116, 116, 116, 0.24);
  z-index: 10000;
}

@media screen and (max-width: 1070px) {
  .form__input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .form__error-text {
    margin-left: 0;
    padding-top: 8px;
  }
}

@media screen and (max-width: 850px) {
  .form__bottom {
    flex-direction: column;
    margin-top: 60px;
  }

  .form__button {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 40px;
  }

  .form__result-wrapper {
    margin: 12px 0 0 0;
  }
}