.m-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  z-index: 100;
  padding: 116px 12px 0;
}

.m-menu__nav {
  margin-bottom: 50px;
}

.m-menu__nav-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.m-menu__nav-element {
  display: flex;
  margin-bottom: 12px;
}

.m-menu__nav-element:last-child {
  margin-bottom: 0;
}

.m-menu__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 146px;
  height: 42px;
  border-radius: 20px;
  /* box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5), -10px -10px 20px rgba(255, 255, 255, 0.5);
  transition: box-shadow .3s; */
}

.m-menu__contacts {
  display: flex;
  flex-direction: column;
}

.m-menu__contacts-row {
  display: flex;
  margin-bottom: 12px;
}

.m-menu__contacts-row:last-child {
  margin-bottom: 0;
}

.m-menu__contact {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}

.m-menu__contact-icon {
  margin-right: 8px;
}