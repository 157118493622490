.button {
  position: relative;
  background: var(--surface-color);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  color: #70A0FF;
  border: none;
  cursor: pointer;
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5), -12px -12px 20px rgba(255, 255, 255, 0.5);
  transition: all .3s;
  animation: buttonShadowFadeOut .15s;
  animation-timing-function: ease-in-out;
  text-decoration: none;
  text-align: center;
  user-select: none;
}

.button:focus {
  outline: none;
}

.button:hover {
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.25), -10px -10px 20px rgba(255, 255, 255, 0.25);
  animation: buttonShadowFadeOut .15s;
  animation-timing-function: ease-in-out;
}

.button:active {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  animation: buttonShadowFadeIn .15s;
  animation-timing-function: ease-in-out;
}

.button_active {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  animation: buttonShadowFadeIn .15s;
  animation: none;
}

.button_active:hover {
  box-shadow: inset 3px 3px 8px rgba(166, 180, 200, 0.75),
      inset -3px -3px 12px rgba(255, 255, 255, 0.75);
  animation: none;
}

.button_active:active {
  box-shadow: inset 8px 8px 13px rgba(166, 180, 200, 0.75), inset -8px -8px 18px rgba(255, 255, 255, 0.75);
  animation: none;
}

.button__text {
  font-size: 14px;
  font-weight: 800;
  color: var(--primary-color_light);
  /* background-color: rgba(0, 46, 115, 0.45);
  color: transparent;
  text-shadow: 0px 1px 1px var(--primary-color_light);
  text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text; */
}

.button_accent {
  color: #EC52DC;
}

.button_big {
  font-size: 24px;
  padding: 22px 80px;
  border-radius: 40px;
}

.button_biggest {
  font-weight: 800;
  font-size: 28px;
  padding: 29px 49px;
  border-radius: 40px;
}

.button_touch:hover {
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5), -12px -12px 20px rgba(255, 255, 255, 0.5);
}

.button_touch:hover .button_touch:active {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  animation: buttonShadowFadeIn .15s;
  animation-timing-function: ease-in-out;
}

.button_touch .button_active:hover {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
}

.button_touch .button_active:hover .button_active:active {
  box-shadow: inset 8px 8px 13px rgba(166, 180, 200, 0.75), inset -8px -8px 18px rgba(255, 255, 255, 0.75);
  animation: none;
}