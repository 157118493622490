.order-form__h2 {
  margin-bottom: 48px;
}

.order-form__text_body {
  margin-bottom: 60px;
}

@media screen and (max-width: 850px) {
  .order-form__h2 {
    margin-bottom: 30px;
    font-size: 38px;
  }
}