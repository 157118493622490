.input-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.input-wrapper:first-of-type {
  margin-top: 0;
}

.input-wrapper:last-of-type {
  margin-bottom: 0;
}

.input-wrapper__inside {
  display: flex;
  width: 100%;
}

.input {
  width: 100%;
  height: 48px;
  padding: 16px 0 16px 36px;
  background: var(--surface-color);
  border: 1px solid var(--box-border-color);
  border-radius: 40px;
  transition: all 0.2s;
  border: 1px solid transparent;
  font-size: 16px;
  box-shadow: inset 3.71835px 3.71835px 7.43671px rgba(166, 180, 200, 0.75), inset -3.71835px -3.71835px 11.1551px rgba(255, 255, 255, 0.75);
}

.input_textarea {
  height: 200px;
  margin-bottom: 15px;
}

.input_textarea:last-child {
  margin-bottom: 0;
}

.input::placeholder {
  color: var(--text-color_light);
}

.input:last-of-type {
  margin-bottom: 0;
}

.input:focus {
  outline: none;
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  background-color: #fff;
}

.input:hover {
  border: 1px solid var(--primary-color);
  background: #f5f5f5;
}

.input_error {
  border: 1px solid var(--error-color) !important;
}

.input__error-wrapper {
  display: flex;
  align-items: center;
}

.input_filled {
  border: 1px solid #FFB800;
}

.input:-moz-ui-invalid {
  box-shadow: none;
  color: var(--error-color);
  border-bottom: 1px solid var(--error-color);
}

.input__error-text {
  font-size: 16px;
  color: var(--error-color);
}

@media screen and (max-width: 1070px) {
  .input-wrapper {
    margin: 6px 0;
  }

  .input__error-wrapper {
    align-items: baseline;
  }
}

@media screen and (max-width: 850px) {
  .input {
    height: 48px;
    padding: 14px 24px 14px;
    box-shadow: inset 2.34968px 2.34968px 4.69937px rgba(166, 180, 200, 0.75), inset -2.34968px -2.34968px 7.04905px rgba(255, 255, 255, 0.75);
  }

  .input_textarea {
    height: 158px;
  }
}