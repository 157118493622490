.blocks-pg__text-body {
  width: 815px;
}

.blocks-pg__block {
  margin-top: 84px;
}

.blocks-pg__h3 {
  margin-bottom: 36px;
}

.blocks-pg__text_mini-body {
  margin-bottom: 36px;
  height: 40px;
}

.blocks-pg__s-grid {
  align-self: center;
  width: calc(100% + 24px);
  margin-top: 42px;
}

.blocks-pg__s-grid-closed > .blocks-pg__s-grid-open-btn {
  opacity: 0.3;
  transition: opacity .3s ease-in-out;
}

.blocks-pg__s-grid-closed:hover > .blocks-pg__s-grid-open-btn {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.blocks-pg__s-grid-open-btn {
  position: absolute;
  right: 24px;
  top: 43px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
}

.blocks-open-icon {
  width: 20px;
  height: 20px;
}

.blocks-pg__s-grid-close-btn {
  position: absolute;
  right: 48px;
  top: 43px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
}

/* .blocks-pg__close-icon {
  width: 20px;
  height: 19px;
} */ 

.blocks-pg__s-grid-close-btn > svg {
  fill: #556CE4;
}

.blocks-pg__card-h4 {
  margin-bottom: 24px;
  font-size: 36px;
  color: var(--primary-color_light);
  font-weight: 600;
}

.blocks-pg__card-h4_additional {
  color: var(--accent-color);
}

.blocks-pg__card {
  display: flex;
  flex-direction: column;
  padding: 36px 48px;
  /* width: 100%; */
  height: 100%;
}

.blocks-pg__card-examples {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.blocks-pg__card-examples_closed {
  
}

.blocks-pg_img-row {
  display: flex;
  flex-wrap: nowrap;
  margin: 12px 0;
  width: 100%;
  height: 100%;
  align-items: flex-start;
}

.blocks-pg_img-row:first-child {
  margin-top: 0;
}

.blocks-pg_img-row:last-child {
  margin-bottom: 0;
}

.blocks-pg__card-img {
  border-radius: 8px;
}

.blocks-pg__card-img_closed {
  /* width: 372px;
  height: 196px; */
  max-width: 372px;
  /* max-height: 196px;  */
  width: 100%;
  height: 100%;
}

.blocks-pg__card-img_opened {
  width: calc(50% - 12px);
  /* width: 420px; */
  /* height: 220px; */
  /* height: 100%; */
  margin: 0;
}

.blocks-pg__card-img_opened:first-child {
  margin-right: 12px;
  margin-left: 0 !important;
}

.blocks-pg__card-img_opened:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 850px) {
  .blocks-pg__card {
    padding: 30px 12px;
  }

  .blocks-pg__text-body {
    width: 100%;
  }
  
  .blocks-pg__card-h4 {
    font-size: 26px;
    
    margin-bottom: 10px;
    padding: 0 12px;
    font-weight: bold; 
  }

  .blocks-pg__card-h4_additional {
    color: var(--accent-color);
  }

  .blocks-pg__text_mini-body {
    font-size: 16px;
    line-height: 20px;
    padding: 0 12px;
    height: auto;
  }

  .blocks-pg__block {
    margin-top: 36px;
  }

  .blocks-pg__h3 {
    margin-bottom: 24px;
  }

  .blocks-pg__s-grid {
    margin-top: 36px;
  }

  .blocks-pg__s-grid-close-btn {
    top: 30px;
    right: 24px;
  }

  .blocks-pg__s-grid-open-btn {
    top: 30px;
    right: 24px;
  }

  /* .blocks-pg__close-icon {
    width: 18px;
    height: 17px;
  } */

  .blocks-pg_img-row {
    flex-direction: column;
    margin: 0;
  }

  .blocks-pg__card-img_opened {
    width: 100%;
    margin: 7.5px 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .blocks-pg__card-img_closed {
    max-width: none;
    max-height: none;
  }


  .blocks-pg_img-row:first-child > .blocks-pg__card-img_opened:first-child {
    margin-top: 0 !important;
  }

  .blocks-pg_img-row:last-child > .blocks-pg__card-img_opened:last-child {
    margin-bottom: 0 !important;
  }
}