.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.header_pc {
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8);
  background-color: #E9EDF0;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1056px;
  height: 100%;
  padding: 14px 48px 15px;
}

.header__logo-wrapper {
  align-self: flex-start;
}

.header__nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.header__nav-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}

.header__nav-row:last-child {
  margin-bottom: 0;
}

.header__main-nav-element {
  margin-right: 7px;
}

.header__main-nav-element:last-child {
  margin-right: 0;
}

.header__sub-nav-element {
  margin-right: 12px;
}

.header__sub-nav-element:last-child {
  margin-right: 0;
}

.header__sub-link {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color_light);
  transition: all .3s;
}

.header__sub-link:hover {
  color: var(--text-color);
}

.header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 146px;
  height: 42px;
  border-radius: 20px;
}

.header__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header__contacts-row {
  display: flex;
  margin-bottom: 12px;
}

.header__contacts-row:last-child {
  margin-bottom: 0;
}

.header__contact {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}

.header__contact-icon {
  margin-right: 8px;
}

/*** --> MOBILE STYLES ***/
.header-mobile {
  position: absolute;
  height: auto;
  display: none;
  justify-content: flex-start;
  padding: 20px 12px 0;
}

.header-mobile__logo-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
}

.header-mobile__logo {
  height: 56px;
}

.header-mobile__menu-icon {
  width: 14px;
  height: 10px;
}

.header-mobile__menu-button-wrapper {
  position: fixed;
  right: 12px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 12px;
}

.header-mobile__menu-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.header-mobile__close-icon {
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 850px) {
  .header_pc {
    display: none;
  }

  .header-mobile {
    display: flex;
  }
}
/*** MOBILE STYLES <-- ***/
