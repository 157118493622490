body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.app {
  overflow: hidden;
}

div,
input,
textarea {
  box-sizing: border-box;
  background-clip: padding-box;
}

.convex {
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8);
}

.convex_smaller {
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5), -10px -10px 20px rgba(255, 255, 255, 0.5);
}

.concavity {
 box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
}

.gradient-border {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: var(--border-color);
  border-radius: inherit;
  z-index: -1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 81px;
  line-height: 100%;
  color: var(--text-color);
  margin-bottom: 48px;
}

h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 68px;
  line-height: 100%;
  color: var(--text-color);
}

h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 100%;
  color: var(--text-color);
}

h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  color: var(--text-color);
}

.text_body {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: var(--text-color);
}

.text_mini-body {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color);
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page__content {
  width: 100%;
  will-change: transform, opacity;
  margin-top: 194px;
  margin-bottom: 160px;
}

.block {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 0;
}

.block:first-child {
  padding-top: 0;
}

.block:last-child {
  padding-bottom: 0;
}

.block__content_column {
  position: relative;
  width: 1056px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 48px;
  z-index: 1000;
}

.block__content_row {
  position: relative;
  width: 1056px;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 48px;
  z-index: 1000;
}

.button {
  position: relative;
  background: var(--surface-color);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  color: #70A0FF;
  padding: 16px 23px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5), -12px -12px 20px rgba(255, 255, 255, 0.5);
  transition: all .3s;
  animation: buttonShadowFadeOut .15s;
  animation-timing-function: ease-in-out;
  text-decoration: none;
  text-align: center;
  user-select: none;
}

.button:focus {
  outline: none;
}

.button:hover {
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.25), -10px -10px 20px rgba(255, 255, 255, 0.25);
  animation: buttonShadowFadeOut .15s;
  animation-timing-function: ease-in-out;
}

.button:active {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  animation: buttonShadowFadeIn .15s;
  animation-timing-function: ease-in-out;
}

.button_active {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  animation: buttonShadowFadeIn .15s;
  animation: none;
}

.button_active:hover {
  box-shadow: inset 3px 3px 8px rgba(166, 180, 200, 0.75),
      inset -3px -3px 12px rgba(255, 255, 255, 0.75);
  animation: none;
}

.button_active:active {
  box-shadow: inset 8px 8px 13px rgba(166, 180, 200, 0.75), inset -8px -8px 18px rgba(255, 255, 255, 0.75);
  animation: none;
}

.button__text {
  font-size: 14px;
  font-weight: 800;
  color: var(--primary-color_light);
  /* background-color: rgba(0, 46, 115, 0.45);
  color: transparent;
  text-shadow: 0px 1px 1px var(--primary-color_light);
  text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text; */
}

.button_accent {
  color: #EC52DC;
}

.button_big {
  font-size: 24px;
  padding: 22px 80px;
  border-radius: 40px;
}

.button_biggest {
  font-weight: 800;
  font-size: 28px;
  padding: 29px 49px;
  border-radius: 40px;
}

.order-result__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(116, 116, 116, 0.24);
  z-index: 10000;
  padding: 0 12px;
}

.order-result__result-card {
  width: 576px;
  background: var(--surface-color);
  border-radius: 40px;
  padding: 48px 60px;
}

.order-result__succes-icon {
  margin-bottom: 36px;
}

.order-result__succes-icon > img {
  width: 95px;
  height: 92px;
}

.order-result__error-title {
  font-family: Montserrat;
  font-size: 72px;
  line-height: 96px;
  font-weight: bold;
  color: var(--primary-color_light);
  margin-bottom: 36px;
}

.order-result__text {
  margin-bottom: 36px;
}

.order-result__contact {
  color: var(--primary-color_light);
  text-decoration: underline;
  font-weight: 500;
}

.order-result__close-popup {
  font-size: 24px;
  font-weight: 600;
}


@media screen and (max-width: 1070px) {
  .block {
    padding: 48px 0 60px 0;
  }

  .block__content_column {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  h1 {
    font-size: 38px;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 20px;
  }

  .block__content_column {
    padding: 0 12px;
  }

  .page__content {
    margin-top: 108px;
    margin-bottom: 84px;
  }

  .text_body {
    font-size: 16px;
    line-height: 20px;
  }

  .text_mini-body {
    font-size: 12px;
    line-height: 16px;
  }

  .order-result__result-card {
    padding: 24px 36px;
    max-width: 400px;
  }

  .order-result__succes-icon {
    margin-bottom: 20px;
  }
  
  .order-result__succes-icon > img {
    width: 52px;
    height: 52px;
  }
  
  .order-result__error-title {
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 12px;
  }

  .order-result__text {
    margin-bottom: 20px;
  }

  .order-result__close-popup {
    font-size: 12px;
  }

  .button:hover {
    box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.5), -12px -12px 20px rgba(255, 255, 255, 0.5);
  }

  .button:hover .button:active {
    box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
    animation: buttonShadowFadeIn .15s;
    animation-timing-function: ease-in-out;
  }

  .button_active:hover {
    box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  }

  .button_active:hover .button_active:active {
    box-shadow: inset 8px 8px 13px rgba(166, 180, 200, 0.75), inset -8px -8px 18px rgba(255, 255, 255, 0.75);
    animation: none;
  }
}

@media screen and (max-width: 500px) {
  .block__content_column {
    width: 100%;
    padding: 0 12px;
  }

  .block__content_row {
    padding: 0 12px;
  }
}

@keyframes buttonShadowFadeIn {
  0% {
    box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.25), -10px -10px 20px rgba(255, 255, 255, 0.25);
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  }
}

@keyframes buttonShadowFadeOut {
  0% {
    box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.75), inset -5px -5px 15px rgba(255, 255, 255, 0.75);
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.25), -10px -10px 20px rgba(255, 255, 255, 0.25);
  }
}